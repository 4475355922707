@import "./vars.scss";

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}
html {
    font-size: 16px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    font-family: $font;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
    overflow-x: hidden;
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

img {
    max-width: 100%;
}
p {
    margin-bottom: 0 !important;
    letter-spacing: -0.3px;
}
ul {
    list-style-type: none;
    list-style-position: inside;
}
.container {
    max-width: 1200px;
    margin: 15px auto;
    padding: 0 15px;
    width: 100%;

    @media (max-width: 768px) {
        margin: 10px auto;
        padding: 0 10px;
    }
}

.scroll__bar {
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
        @include media-576 {
            width: 4px;
            height: 4px;
        }
    }
    &--sm {
        &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
        }
    }
    &::-webkit-scrollbar-track {
        background-color: #dbdfe6;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-grey;
        border-radius: 10px;
    }
    // Firefox
    // scrollbar-width: thin;
    // scrollbar-color: $c-grey #dbdfe6;

    // @media (max-width: 576px) {
    // 	scrollbar-width: thin; // Firefox doesn't have an option to set the exact size in px
    // }
}

@import "./component.scss";
@import "./order.scss";

// fonts
$font: "Roboto", sans-serif;

// colors
$color-b: #000;
$color-w: #fff;
$c-main: #3d639d;
$c-primary: #00c48c;
$c-success: #01bf89;
$c-text: #262728;
$c-yellow: #fecb4d;
$c-background: #f3f5f8;
$c-background-hover: #e9eaec;
$c-grey: #bbc2d0;
$c-gray-light: #ced4da;
$c-gray-dark: #495057;
$c-pink: #ff647c;
$c-white: #3a0606;
$c-error: #ec3920;
$c-dark: #262728;

@mixin media-1320 {
    @media (max-width: 1320px) {
        @content;
    }
}

@mixin media-1200 {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin media-1080 {
    @media (max-width: 1080px) {
        @content;
    }
}

@mixin media-992 {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin media-768 {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin media-576 {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin media-480 {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin media-360 {
    @media (max-width: 360px) {
        @content;
    }
}

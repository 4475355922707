.order {
    &__block {
        border-radius: 16px;
        padding: 20px;
        margin-bottom: 20px;
        background-color: #f3f5f8;

        @media (max-width: 768px) {
            padding: 15px;
            margin-bottom: 15px;
        }

        &--green {
            background-color: #00c48c;
        }
        &--danger {
            background-color: #f6ada3;
        }
        &--owner {
            background-color: #48e2b6;
            div {
                color: #262728;
            }
        }
        &--no-owner {
            background-color: #f6ada3;
            div {
                color: #262728;
            }
        }
    }

    &__status {
        border: 1px solid;

        &_title {
            font-size: 1.375rem;
            font-weight: 600;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                font-size: 1.2rem;
                margin-bottom: 8px;
            }
        }

        &_time {
            font-size: 0.875rem;
            font-weight: 400;
            color: #bbc2d0;
        }

        &_btn {
            width: 100%;
            max-width: 240px;
            margin-top: 8px;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
    }

    &__products {
        &_item {
            display: flex;
            flex-wrap: wrap;

            &:not(:last-child) {
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #bbc2d0;
            }

            &__number {
                margin-right: 16px;
                @media (max-width: 768px) {
                    margin-right: 10px;
                }
            }

            &--danger {
                &:not(:last-child) {
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #000;
                }
            }
        }

        // &_table {
        //     font-size: 0.875rem;
        //     width: 100%;
        //     overflow-x: auto;

        //     &__header {
        //         color: #bbc2d0;
        //     }

        //     &__header,
        //     &__body {
        //         display: flex;
        //         align-items: center;
        //         margin-bottom: 20px;
        //         flex-wrap: wrap;

        //         // @media (max-width: 768px) {
        //         //     margin-bottom: 15px;
        //         // }

        //         & > div {
        //             margin-right: auto;
        //             width: 120px;

        //             // @media (max-width: 768px) {
        //             //     width: 100%;
        //             //     margin-bottom: 10px;
        //             // }
        //         }
        //     }

        //     &__box {
        //         display: flex;
        //         align-items: center;
        //         margin-bottom: 5px;
        //         flex-wrap: wrap;

        //         // @media (max-width: 768px) {
        //         //     flex-direction: column;
        //         //     align-items: flex-start;
        //         // }

        //         & > div {
        //             margin-right: auto;
        //             min-width: 350px;

        //             // @media (max-width: 768px) {
        //             //     min-width: 100%;
        //             //     margin-bottom: 10px;
        //             // }
        //         }
        //     }
        // }
    }

    &__info {
        padding: 20px;
        border: 1px solid #00c48c;
        border-radius: 16px;
        font-weight: 500;

        @media (max-width: 768px) {
            padding: 15px;
        }
    }

    &__checkout {
        &_btn {
            width: 100%;
            max-width: 240px;
        }
    }

    &__title {
        color: #3d639d;
        margin-bottom: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .hint {
            font-size: 22px;
            color: #3d639d;
            border: none;
            background-color: inherit;
        }
        .danger {
            color: #ff0000;
            border: none;
            font-size: 22px;
            background-color: inherit;
        }
    }

    &__twice {
        display: flex;
        justify-content: space-between;

        & > div {
            width: calc(50% - 10px);
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .accept {
            color: #00c48c;
            border-color: #00c48c;
        }

        & > * {
            width: calc(50% - 10px);
        }
    }

    &__group {
        margin-bottom: 8px;

        display: flex;
        align-items: flex-start;

        &_type {
            color: #bbc2d0;
            min-width: 200px;
            max-width: 400px;
            font-size: 14px;

            text-overflow: ellipsis;
            word-wrap: break-word;

            @media (max-width: 480px) {
                min-width: 120px;
            }
        }

        &_value {
            color: #262728;

            text-overflow: ellipsis;
            word-wrap: break-word;
            width: calc(100% - 200px);

            @media (max-width: 480px) {
                width: calc(100% - 120px);
            }
        }
    }
}

.decline {
    color: #ec3920;
    border-color: #ec3920;
}

.icon {
    &__green {
        color: #00c48c;
        font-size: 20px;
        font-weight: 600;
    }
    &__btn {
        cursor: pointer;
    }
}

.order__signature {
    border: 5px dashed $c-primary;
    padding: 20px;
    border-radius: 25px;
    width: 100%;
    // max-width: 400px;

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 15px;
        margin-bottom: 15px;
    }

    @media (max-width: 576px) {
        padding: 10px;
        margin-bottom: 10px;
    }

    .order__group {
        margin-bottom: 8px;

        @media (max-width: 360px) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 10px;

            &_type {
                min-width: 100%;
                margin-bottom: 4px;
            }

            &_value {
                width: 100%;
            }
        }
    }
}

.signatures-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }

    @media (max-width: 576px) {
        gap: 10px;
        margin-top: 15px;
    }

    @media (max-width: 560px) {
        gap: 8px;
        margin-top: 12px;
    }

    .signature {
        flex: 1;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 8px;

        @media (max-width: 768px) {
            padding: 12px;
        }

        @media (max-width: 576px) {
            padding: 10px;
        }

        @media (max-width: 560px) {
            padding: 8px;
        }

        &__title {
            font-weight: 600;
            margin-bottom: 15px;
            font-size: 16px;

            @media (max-width: 768px) {
                margin-bottom: 10px;
                font-size: 15px;
            }

            @media (max-width: 576px) {
                margin-bottom: 8px;
                font-size: 14px;
            }

            @media (max-width: 560px) {
                margin-bottom: 6px;
                font-size: 13px;
            }
        }

        &__image {
            width: 100%;
            max-height: 100px;
            object-fit: contain;

            @media (max-width: 576px) {
                max-height: 80px;
            }

            @media (max-width: 560px) {
                max-height: 60px;
            }
        }
    }
}
.signature-section {
    width: 100%;
}
.order__products_table {
    font-size: 0.875rem;
    width: 100%;
    overflow-x: auto; // Enable horizontal scrolling when content overflows
    white-space: nowrap; // Prevent wrapping of table content

    &__header,
    &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 400px; // Ensure columns have enough space in smaller screens
        padding: 10px 0;
    }

    &__header {
        color: #bbc2d0;
        font-weight: 600;
        // border-bottom: 1px solid #ccc;
    }

    &__box {
        border-bottom: 1px solid #eee;
    }

    & > div > div {
        padding: 0 10px; // Adjust spacing between columns
    }
}

.handle__atrribute--item {
    display: grid !important;
    gap: 20px;
    grid-template-columns: 0.2fr 3fr 3fr 0.5fr;
    align-items: flex-end;
    width: 100%;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 10px;
    }
}

// cardd
.cardd {
    background: $c-background;
    border-radius: 16px;
    padding: 20px;
    color: $c-text;

    @media (max-width: 768px) {
        padding: 15px;
    }

    &__head {
        color: $c-main;
        font-weight: 600;
        letter-spacing: 0.01em;
        margin-bottom: 20px !important;

        @media (max-width: 768px) {
            margin-bottom: 15px !important;
            font-size: 1.1rem;
        }
    }
    &__box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        @media (max-width: 992px) {
            gap: 10px;
        }

        @media (max-width: 576px) {
            grid-template-columns: 1fr;
            gap: 5px;
        }
    }
    &__box-item {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        &--title {
            color: $c-grey;
            font-size: 1rem;
        }
        &--value {
            font-size: 1rem;
            font-weight: 400;
        }
    }
    &__content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }

        @media (max-width: 576px) {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }
    &__rows {
        display: flex;
        flex-direction: column;
        gap: 20px;
        &.grey {
            color: $c-grey;
        }

        @media (max-width: 768px) {
            gap: 10px;
        }
    }
}

.table-orderinfo {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 0 0 16px 16px;
    background-color: $c-background;
    border-collapse: separate;
    padding: 15px 25px 25px 25px;

    @media (max-width: 768px) {
        padding: 10px;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    th:nth-child(1) {
        width: 5%;
        @media (max-width: 768px) {
            width: 60px;
        }
    }

    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6) {
        width: 19%;
        @media (max-width: 768px) {
            min-width: 150px;
        }
    }

    th,
    td {
        padding: 15px 0 15px 20px;
        text-align: left;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.3px;
        border: 1px solid #bbc2d0;

        @media (max-width: 768px) {
            padding: 10px;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
        }
    }

    th {
        color: #f06292; // Adjust this color as needed
    }

    td {
        color: #262728;
    }

    tr:first-child th:first-child {
        border-top-left-radius: 16px;
    }

    tr:first-child th:last-child {
        border-top-right-radius: 16px;
    }

    tr:last-child td:first-child {
        border-bottom-left-radius: 16px;
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: 16px;
    }

    tr:not(:last-child) th,
    tr:not(:last-child) td {
        border-bottom: none !important;
    }

    tr th:not(:first-child),
    tr td:not(:first-child) {
        border-left: none !important;
    }

    .status {
        color: #f06292; // Adjust this color as needed
    }
    .pinkCol {
        color: #f06292; // Adjust this color as needed
    }

    .greenCol {
        color: #3f51b5; // Adjust this color as needed
    }

    .greyCol {
        color: #9e9e9e; // Adjust this color as needed
    }
    .bold {
        font-weight: bold;
    }

    &__days {
        width: 125px;
        padding: 5px 0;
        margin-top: 8px;
        text-align: center;
        border-radius: 6px;
        color: #ffffff;
        display: inline-block;
    }

    .bgBlue {
        background-color: #2196f3; // Adjust this color as needed
    }

    .bgGreen {
        background-color: #4caf50; // Adjust this color as needed
    }

    .bgYellow {
        background-color: #ffeb3b; // Adjust this color as needed
    }

    .bgRed {
        background-color: #f44336; // Adjust this color as needed
    }

    .bgBlack {
        background-color: #000000;
    }
}
.table-clientpay {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    margin-top: 20px;
    border-radius: 16px 16px;
    background-color: $c-background;
    border-collapse: separate;
    padding: 15px 25px 25px 25px;

    @media (max-width: 768px) {
        padding: 10px;
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    &-head {
        tr {
            th {
                color: $c-primary;
                padding: 10px;
            }
            th:first-child {
                padding-left: 20px;
            }
            th:last-child {
                padding-right: 20px;
            }
        }
    }
    &-body {
        border-radius: 16px;
    }
    &-row {
        td {
            background: $c-background;
            padding: 10px;
            // padding-bottom: 15px;
            // padding-top: 20px;
        }
        td:first-child {
            border-top-left-radius: 16px;
            padding-left: 20px;
        }
        td:last-child {
            border-top-right-radius: 16px;
            padding-right: 20px;
        }
    }
    .lbl {
        color: $c-grey;
        padding: 8px 8px 8px 20px;
        background-color: $c-background;
    }
    .product {
        background-color: $c-background;
        color: $c-text;
        padding: 8px 8px 8px 20px;
    }
}
